import { Component } from '@angular/core';
import { PopupsService } from '@shared/popups/popups.service';
import { TermsOfUseComponent } from '@pages/auth/components/terms-of-use/terms-of-use.component';
import { FAQComponent } from '@pages/components/f-a-q/f-a-q.component';

@Component({
  selector: 'eng-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(private popupsService: PopupsService) {}

  public async showTermsOfUse(): Promise<void> {
    this.popupsService.showDialog(TermsOfUseComponent, {
      panelClasses: 'TermsOfUse',
    });
  }

  public async showFAQ(): Promise<void> {
    this.popupsService.showDialog(FAQComponent, {
      panelClasses: 'TermsOfUse',
    });
  }
}
