import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PopupsService } from '@shared/popups/popups.service';

@Component({
  selector: 'eng-f-a-q',
  templateUrl: './f-a-q.component.html',
  styleUrls: ['./f-a-q.component.scss'],
})
export class FAQComponent {
  constructor(
    public readonly popupsService: PopupsService,
    public dialogRef: MatDialogRef<FAQComponent>,
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
