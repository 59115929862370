<ng-template [engBreakpointsClass]="['Desktop']"></ng-template>
<div class="footer pp-note text-align-center">
  <label class="h7 bold-3 text-align-center snow">
    <span tabindex="0" class="text-link" (click)="showTermsOfUse()">{{
      'default.termsUseAndPolicy' | translate
    }}</span>
  </label>
  <label class="h7 bold-3 text-align-center snow faq">
    <span tabindex="0" class="" (click)="showFAQ()">{{ 'default.faq' | translate }}</span>
  </label>
  <a target="_blank" href="https://beomytech.co.il" class="h7 bold-3 text-align-center snow left">
    <span>
      {{ 'default.beomyTech' | translate }}
      <span class="text-link">{{ 'default.appDevelopment' | translate }}</span>
    </span>
  </a>
</div>
