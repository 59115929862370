import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { FooterComponent } from './footer.component';
import { FAQModule } from '@pages/components/f-a-q/f-a-q.module';
import { TermsOfUseModule } from '@pages/auth/components/terms-of-use/terms-of-use.module';
import { EngBreakpointsModule } from '@shared/core/directives/breakpoints/breakpoints.module';
import { PopupAskModule } from '@shared/popups/ask/popup-ask.module';
import { PopupStatusModule } from '@shared/popups/status/popup-status.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    TranslateModule,
    EngBreakpointsModule,
    FAQModule,
    TermsOfUseModule,
    PopupAskModule,
    PopupStatusModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
