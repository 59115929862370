<div class="separator">
  <h2 class="accent title bold-5 accent">
    {{ 'FAQ' }}
  </h2>

  <mat-dialog-content>
    <div class="paragraph bold-3 dark h9 line-height-22">
      <span class="terms-use-title">
        This first ever customizable interactive site enables teachers, educators, students and
        anyone who loves games to build their own Bingo boards on any topic they desire, and to play
        it interactively, simultaneously, remotely or in class, and gamify the learning experience.
      </span>
      <p></p>
      <span class="terms-use-title">Instructions – how to sign up</span>
      <br />
      <br />
      <span class="terms-use-title">This site is completely free!</span>
      <br />
      <span>
        All you need to do is sign up using your email, then receive a confirmation mail/number by
        text and start adding games to your library.
      </span>
      <br />
      <br />

      <span class="terms-use-title">How to add an existing game</span>
      <br />
      <br />
      <span class="terms-use-title">Public library</span>
      <br />
      <br />
      <span class="terms-use-title">
        <u>
          On the public page (on the top) there are public games you can add to your library by
          pressing the duplicate link in each game you desire.
        </u>
      </span>
      <br />
      <br />
      <span class="terms-use-title">How to build a new game</span>
      <br />
      <br />
      <p>After choosing a topic for your bingo board,</p>
      <p>choose the size of your board</p>
      <p>The options are -</p>
      <p>3x3 (9 questions)</p>
      <p>4x4 (16 questions)</p>
      <p>5x5 (25 questions)</p>
      <br />
      <br />
      <span class="terms-use-title">You can then choose between -</span>
      <br />
      <br />
      <p>picture to word,</p>
      <p>word to word</p>
      <p>picture to picture</p>
      <p>
        (If you are choosing to use pictures it is advised to collect your pics in a file on your
        computer and upload when filling in the questions or answers).
      </p>
      <p>
        It is not recommended to mix word/picture style of board in the same game, meaning choose
        one type of board, picture to word, word to word, picture to picture. And if you have
        selected to use pictures as answers or questions, make sure they are all either answers or
        questions.
      </p>
      <br />
      <br />
      <p>
        <span class="terms-use-title">If you chose to use pictures</span>
        , add something in the question or answer box. Such as a question mark (<span
          class="terms-use-title"
          >?</span
        >) or any other word/letter/sign. If the answers or questions blocks are empty you will not
        be able to save the game.
      </p>
      <p>You also need to choose the time limit per answer (options are 10 - 60 seconds)</p>
      <p>You can choose a different time limit for each question.</p>
      <p>The game supports ---- languages ?</p>
      <p>
        When you’re all done filling in the questions and answers you press<span
          class="terms-use-title"
        >
          FINISH</span
        >
        at the bottom and bam! The game will appear in your
        <span class="terms-use-title">MY GAMES</span> page.
      </p>
      <br />
      <br />
      <span class="terms-use-title">Instructions – How to play</span>
      <br />
      <br />
      <p>When you want to play a game you need at least one player and no more than 10.</p>
      <p>
        You choose your game from the “<span class="terms-use-title">my games</span>” page, press
        play and a screen with a code will appear,
      </p>
      <p>
        You then press “<span class="terms-use-title">SHARE”</span> and send the link via zoom chat,
        email, or any other form you wish.
      </p>
      <p>The game generates a different code each time.</p>
      <br />
      <br />
      <p>
        Once your students have received the code, they can enter the game. Each player that entered
        his name will show up on the game manager screen.
      </p>
      <br />
      <br />
      <p>
        You should wait till all your students have logged in because a plyer cannot join after the
        game has started.
      </p>
      <br />
      <br />
      <p>When you wish to start playing you press “<span class="terms-use-title">start”.</span></p>
      <br />
      <br />
      <p>The first question will appear on the screen – and on the students’ devices,</p>
      <p>
        After the game manager presses “<span class="terms-use-title">next</span>” the students will
        see their bingo board and be able to
      </p>
      <p>press the right answer.</p>
      <br />
      <br />
      <p>
        After they have all answered or the time limit to answer has passed, the right answer will
        appear.
      </p>
      <br />
      <br />
      <p>
        The game manager needs to press “<span class="terms-use-title">next</span>” to continue to
        the next question.
      </p>
      <br />
      <br />
      <p>After each round you will be able to see which student answer what answer.</p>
      <br />
      <br />
      <p>
        Once a player has a row or a column of green marked cubes (correct answers) the game ends
        and you have the winner screen.
      </p>
      <br />
      <br />
      <p align="center">
        <span class="terms-use-title">FAQ</span>
      </p>

      <ol class="list-style-inside">
        <li>
          <strong>Why does the device show a red X ?</strong>
          <ol>
            <li>
              If a player plays from a mobile phone, they have to place it horizontally, otherwise
              their screen will show a red X.
            </li>
          </ol>
        </li>
        <li>
          <strong>Can I join once the game has already started ?</strong>
          <ol>
            <li>A player cannot join or re-join the game after it has started</li>
          </ol>
        </li>
        <li>
          <strong>Do all the players have all the answers?</strong>
          <ol>
            <li>All players have the same answers in a different order.</li>
          </ol>
        </li>
        <li>
          <strong>Can a player change the answer after choosing ?</strong>
          <ol>
            <li>players cannot change their answer after they have selected it.</li>
          </ol>
        </li>
        <li>
          <strong>If my choice didn't register ?</strong>
          <ol>
            <li>Sometimes the selection requires a couple of presses for it to register.</li>
          </ol>
        </li>
        <li>
          <strong>Why is the player not seeing the answer board ?</strong>
          <ol>
            <li>
              The players will not see the board until the game manager presses the
              &ldquo;next&rdquo; button each time, then they have to scroll down a bit to see the
              board.
            </li>
          </ol>
        </li>
        <li>
          <strong>Can I choose a different time limit for each question?</strong>
          <ol>
            <li>You can choose a different time limit for each question.</li>
          </ol>
        </li>
        <li>
          <strong>Can I share my games with other teachers/users ?</strong>
          <ol>
            <li>
              You can share your games by new/duplicate link and then the person you shared it with,
              may add the game to his library.
            </li>
          </ol>
        </li>
        <li>
          <strong
            >If the site shows you a screen that states that you haven&rsquo;t finished your
            previous game</strong
          >
          <ol>
            <li>
              you should press FINISH at the bottom and then it will allow you to start a new game.
            </li>
          </ol>
        </li>
        <li>
          <strong>How many players can play at the same time ?</strong>
          <ol>
            <li>The game is limited to maximum 10 players.</li>
          </ol>
        </li>
        <li>
          <strong>How can I edit a game ?</strong>
          <ol>
            <li>
              The edit options in each game are available in this mark &ndash; The edit menu lets
              the game editor- delete, edit, duplicate, etc.
            </li>
          </ol>
        </li>
        <li>
          <strong>How van I make it more fun to play ?</strong>
          <ol>
            <li>
              To make the game more challenging you can have a competition on who answers correctly
              first in each round.
            </li>
          </ol>
        </li>
        <li>
          <strong>Is the use of the site free ?</strong>
          <ol>
            <li>The registration and use of the site are free.</li>
          </ol>
        </li>
      </ol>

      <br />
      <br />
      <p class="email">
        For any questions/suggestions or problems – please contact us at -
        <a href="mailto:lilibip.info&#64;gmail.com">lilibip.info&#64;gmail.com</a>.
      </p>
      <br />
      <br />
      <p align="center">
        <span class="terms-use-title">More fun games to come soon!!!</span>
      </p>
      <p align="center">
        <span class="terms-use-title">The Lilibip team</span>
      </p>
    </div>

    <div class="block-button">
      <button mat-flat-button color="primary" class="h6 shadow" (click)="onClose()">
        {{ 'default.back' | translate }}
      </button>
    </div>
  </mat-dialog-content>
</div>
